import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import RoomControl from './modules/RoomControl'
import Shopping from './modules/Shopping'

const modules = {
  RoomControl,
  Shopping,
}

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: true,
  // url_base: 'https://sistema.terrazzosuite.com/api/',
  // path: 'https://sistema.terrazzosuite.com/api/',

  url_base: 'https://sistema.terrazzosuite.com/api/',
  path: 'https://sistema.terrazzosuite.com/api/',


  // url_base: 'ishotel.reyfact.com/api/',
  //demo
  // https://hoteltamboreal.tacnafacts.com/api/


}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}



export default new Vuex.Store({
  state,
  mutations,
  modules
})