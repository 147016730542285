import Vue from 'vue'
import Router from 'vue-router'
const je = require('json-encrypt')

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')



// Views - Components

const Forms = () => import('@/views/base/Forms')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

const userGuard = (to, from, next) => {

  let user = window.localStorage.getItem("user");
  let user_permissions = window.localStorage.getItem("user_permissions");
  
  if (user == null || user == undefined) {    
    next('/')
  }else{
    user = JSON.parse(JSON.parse(je.decrypt(user)));
    if (user.api_token.length != 60) {
        next('/')
    }else{
      let isAuthenticated = false;
      if (user_permissions == null || user_permissions == undefined) {
        next({ name: 'Page404' })
      }else{
        user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
        if (user_permissions.indexOf(to.name) > -1) isAuthenticated = true
      }
      if (!isAuthenticated) next({ name: 'Page404' })
      else next()
      
    }
  }
  

}


Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/',
      name: 'Login',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/',
          name: 'LoginPage',
          component: Login
        },
      ]
    },
    {
      path: "/empresa",
      redirect: "/empresa/editar",
      name: "Eempresa",
      component: TheContainer,
      children: [
        {
          path: "editar",
          name: "BusinessEdit",
          component: () => import("@/views/business/Edit"),
          beforeEnter: userGuard,
        },
      ],
    },
    {
      path: '/inicio',
      redirect: '/inicio/inicio',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'inicio',
          name: 'Dashboard',
          component: Dashboard,          
        },
        {
          path: '404',
          name: 'Page404',
          component: Page404,
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500,
        },
      ]
    },

    {
      path: '/usuario',
      redirect: '/usuario/listar',
      name: 'Usuario',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'UserList',
          component: ()=>import('@/views/user/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'UserAdd',
          component: ()=>import('@/views/user/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_user',
          name: 'UserEdit',
          component: ()=>import('@/views/user/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_user',
          name: 'UserView',
          component: ()=>import('@/views/user/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },

    {
      path: '/tipo-usuario',
      redirect: '/tipo-usuario/listar',
      name: 'TipoUsuario',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'UserTypeList',
          component: ()=>import('@/views/usertype/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'UserTypeAdd',
          component: ()=>import('@/views/usertype/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_user_type',
          name: 'UserTypeEdit',
          component: ()=>import('@/views/usertype/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_user_type',
          name: 'UserTypeView',
          component: ()=>import('@/views/usertype/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/cliente',
      redirect: '/cliente/listar',
      name: 'Cliente',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'ClientList',
          component: ()=>import('@/views/client/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'ClientAdd',
          component: ()=>import('@/views/client/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_client',
          name: 'ClientEdit',
          component: ()=>import('@/views/client/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_client',
          name: 'ClientView',
          component: ()=>import('@/views/client/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/proveedor',
      redirect: '/proveedor/listar',
      name: 'Proveedor',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'ProviderList',
          component: ()=>import('@/views/provider/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'ProviderAdd',
          component: ()=>import('@/views/provider/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_provider',
          name: 'ProviderEdit',
          component: ()=>import('@/views/provider/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_provider',
          name: 'ProviderView',
          component: ()=>import('@/views/provider/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/categoria',
      redirect: '/categoria/listar',
      name: 'Categoria',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'CategoryList',
          component: ()=>import('@/views/category/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'CategoryAdd',
          component: ()=>import('@/views/category/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_category',
          name: 'CategoryEdit',
          component: ()=>import('@/views/category/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_category',
          name: 'CategoryView',
          component: ()=>import('@/views/category/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },
    {
      path: '/producto',
      redirect: '/producto/listar',
      name: 'Producto',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'ProductList',
          component: ()=>import('@/views/product/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'ProductAdd',
          component: ()=>import('@/views/product/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_product',
          name: 'ProductEdit',
          component: ()=>import('@/views/product/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_product',
          name: 'ProductView',
          component: ()=>import('@/views/product/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/comprobante',
      redirect: '/comprobante/listar',
      name: 'Comprobante',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'VoucherList',
          component: ()=>import('@/views/voucher/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'VoucherAdd',
          component: ()=>import('@/views/voucher/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_voucher',
          name: 'VoucherEdit',
          component: ()=>import('@/views/voucher/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_voucher',
          name: 'VoucherView',
          component: ()=>import('@/views/voucher/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },

  
    {
      path: '/compra',
      redirect: '/compra/listar',
      name: 'Compra',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'ShoppingList',
          component: ()=>import('@/views/shopping/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'ShoppingAdd',
          component: ()=>import('@/views/shopping/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_shopping',
          name: 'ShoppingEdit',
          component: ()=>import('@/views/shopping/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_shopping',
          name: 'ShoppingView',
          component: ()=>import('@/views/shopping/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/venta',
      redirect: '/venta/listar',
      name: 'Venta',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'SaleList',
          component: ()=>import('@/views/sale/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'SaleAdd',
          component: ()=>import('@/views/sale/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_sale',
          name: 'SaleEdit',
          component: ()=>import('@/views/sale/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_sale',
          name: 'SaleView',
          component: ()=>import('@/views/sale/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/tipo-habitacion',
      redirect: '/tipo-habitacion/listar',
      name: 'TipoHabitacion',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'TypeRoomList',
          component: ()=>import('@/views/type-room/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'TypeRoomAdd',
          component: ()=>import('@/views/type-room/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_type_room',
          name: 'TypeRoomEdit',
          component: ()=>import('@/views/type-room/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_type_room',
          name: 'TypeRoomView',
          component: ()=>import('@/views/type-room/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },



    {
      path: '/habitacion',
      redirect: '/habitacion/listar',
      name: 'Habitacion',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'RoomList',
          component: ()=>import('@/views/room/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'RoomAdd',
          component: ()=>import('@/views/room/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_room',
          name: 'RoomEdit',
          component: ()=>import('@/views/room/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_room',
          name: 'RoomView',
          component: ()=>import('@/views/room/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },



    {
      path: '/control-habitacion',
      redirect: '/control-habitacion/listar',
      name: 'ControlHabitacion',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'RoomControlList',
          component: ()=>import('@/views/room-control/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'RoomControlAdd',
          component: ()=>import('@/views/room-control/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_room',
          name: 'RoomControlEdit',
          component: ()=>import('@/views/room-control/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_room',
          name: 'RoomControlView',
          component: ()=>import('@/views/room-control/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/control-habitacion-tablet',
      redirect: '/control-habitacion-tablet/listar',
      name: 'ControlHabitacionTablet',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'RoomControlTabletList',
          component: ()=>import('@/views/room-control-tablet/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'RoomControlTabletAdd',
          component: ()=>import('@/views/room-control-tablet/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_room',
          name: 'RoomControlTabletEdit',
          component: ()=>import('@/views/room-control-tablet/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_room',
          name: 'RoomControlTabletView',
          component: ()=>import('@/views/room-control-tablet/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },

    {
      path: '/pago-a-cuenta',
      redirect: 'pago-a-cuenta/listar',
      name: 'PaymentAccount',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'PaymentAccountList',
          component: ()=>import('@/views/payment-account/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'PaymentAccountAdd',
          component: ()=>import('@/views/payment-account/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_payment_account',
          name: 'PaymentAccountEdit',
          component: ()=>import('@/views/payment-account/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_payment_account',
          name: 'PaymentAccountView',
          component: ()=>import('@/views/payment-account/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/pago',
      redirect: 'pago/listar',
      name: 'Payment',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'PaymentList',
          component: ()=>import('@/views/payment/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'PaymentAdd',
          component: ()=>import('@/views/payment/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_payment',
          name: 'PaymentEdit',
          component: ()=>import('@/views/payment/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_payment',
          name: 'PaymentView',
          component: ()=>import('@/views/payment/View'),
          props: true,
          beforeEnter: userGuard
        }, 
        {
          path: 'nota-de-credito/:id_payment',
          name: 'PaymentNoteCredit',
          component: ()=>import('@/views/payment/AddNoteCredit'),
          props: true,

        },  

        
      ]
    },



    
    {
      path: '/caja',
      redirect: '/caja/listar',
      name: 'Caja',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'CashList',
          component: ()=>import('@/views/cash/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'CashAdd',
          component: ()=>import('@/views/cash/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_cash',
          name: 'CashEdit',
          component: ()=>import('@/views/cash/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_cash',
          name: 'CashView',
          component: ()=>import('@/views/cash/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },



    {
      path: '/ingresos',
      redirect: '/ingresos/listar',
      name: 'Ingresos',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'IncomeList',
          component: ()=>import('@/views/income/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'IncomeAdd',
          component: ()=>import('@/views/income/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_income',
          name: 'IncomeEdit',
          component: ()=>import('@/views/income/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_income',
          name: 'IncomeView',
          component: ()=>import('@/views/income/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/egresos',
      redirect: '/egresos/listar',
      name: 'Egresos',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'ExpenseList',
          component: ()=>import('@/views/expense/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'ExpenseAdd',
          component: ()=>import('@/views/expense/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_expense',
          name: 'ExpenseEdit',
          component: ()=>import('@/views/expense/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_expense',
          name: 'ExpenseView',
          component: ()=>import('@/views/expense/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },

    {
      path: '/rooming-list',
      redirect: '/rooming-list/listar',
      name: 'RoomingList',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'RoomingListList',
          component: ()=>import('@/views/rooming-list/List'),
          beforeEnter: userGuard
        }
      ]
    },
    
    {
      path: '/mantenimiento',
      redirect: '/mantenimiento/listar',
      name: 'Mantenimiento',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'MaintenanceList',
          component: ()=>import('@/views/maintenance/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'MaintenanceAdd',
          component: ()=>import('@/views/maintenance/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_maintenance',
          name: 'MaintenanceEdit',
          component: ()=>import('@/views/maintenance/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_maintenance',
          name: 'MaintenanceView',
          component: ()=>import('@/views/maintenance/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },




    {
      path: '/reserva',
      redirect: '/reserva/listar',
      name: 'Reserva',
      component: TheContainer,
      children: [
        {
          path: 'listar',
          name: 'ReservationList',
          component: ()=>import('@/views/reservation/List'),
          beforeEnter: userGuard
        },
        {
          path: 'nuevo',
          name: 'ReservationAdd',
          component: ()=>import('@/views/reservation/Add'),
          beforeEnter: userGuard
          
        },
        {
          path: 'editar/:id_reservation',
          name: 'ReservationEdit',
          component: ()=>import('@/views/reservation/Edit'),
          props: true,
          beforeEnter: userGuard
          
        },
        {
          path: 'ver/:id_reservation',
          name: 'ReservationView',
          component: ()=>import('@/views/reservation/View'),
          props: true,
          beforeEnter: userGuard
          
        },  
      ]
    },


    {
      path: '/reporte',
      redirect: '/reporte/huespedes',
      name: 'Reporte',
      component: TheContainer,
      children: [
        {
          path: 'huespedes',
          name: 'ReportGuestList',
          component: ()=>import('@/views/report/Guest'),
          beforeEnter: userGuard
        },
        {
          path: 'mincetur',
          name: 'ReportMinceturList',
          component: ()=>import('@/views/report/Mincetur'),
          beforeEnter: userGuard
        },
        {
          path: 'pagos',
          name: 'ReportPaymentList',
          component: ()=>import('@/views/report/Payment'),
          beforeEnter: userGuard
        },
        {
          path: 'comprobantes',
          name: 'ReportVoucherList',
          component: ()=>import('@/views/report/Voucher'),
          beforeEnter: userGuard
        },
       
      ]
    },



  ]
}


