const axios = require("axios").default;
const je = require("json-encrypt");
//to handle state
const state = {
    shopping_detail: [],
    total_shopping:{
        taxed_operation: (0).toFixed(2),
        exonerated_operation: (0).toFixed(2),
        unaffected_operation: (0).toFixed(2),
        discount: (0).toFixed(2),
        subtotal: (0).toFixed(2),
        igv: (0).toFixed(2),
        total: (0).toFixed(2),
    },
    module_sp: 'Shopping',
    role_sp: '2',
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadAddShoppingDetail(context,product) {
        let validate = true;
        let detail = context.state.shopping_detail;
        for (let index = 0; index < detail.length; index++) {
            if (detail[index].id_product == product.id_product) {
                detail[index].quantity = parseFloat(detail[index].quantity) + parseFloat(product.quantity);
                detail[index].total_price = parseFloat(detail[index].unit_price) * parseFloat(detail[index].quantity);
                detail[index].unit_price = parseFloat(detail[index].unit_price).toFixed(2);
                detail[index].total_price = parseFloat(detail[index].total_price).toFixed(2);
                validate = false;
                context.dispatch('mLoadTotalsShoppingDetail');
                break;
            }
        }
        if (validate) {
            context.commit('mAddShoppingDetail',product);
            context.dispatch('mLoadTotalsShoppingDetail');
        }
        
    },

    mLoadEditShoppingDetail(context,id_product){
        let detail = context.state.shopping_detail;
        for (let index = 0; index < detail.length; index++) {
            if (detail[index].id_product == id_product) {
                detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity) ;
                detail[index].unit_price = detail[index].unit_price.length == 0 ? (0).toFixed(2) : parseFloat(detail[index].unit_price).toFixed(2);
                detail[index].total_price = parseFloat(detail[index].unit_price) * parseFloat(detail[index].quantity);
                detail[index].total_price = parseFloat(detail[index].total_price).toFixed(2);
                // context.commit('mEditShoppingDetail',index,detail[index]);
                context.dispatch('mLoadTotalsShoppingDetail');
                break;
            }
        }
    },

    mLoadDeleteShoppingDetail(context,id_product){
        let detail = context.state.shopping_detail;

        for (let index = 0; index < detail.length; index++) {
            if (detail[index].id_product == id_product) {
                context.commit('mDeleteShoppingDetail',index);
                break;
            }
        }
    },

    mLoadResetShoppingDetail(context){
        context.commit('mResertShoppingDetail');
        context.dispatch('mLoadTotalsShoppingDetail');
    },


    mLoadTotalsShoppingDetail(context){
        let total = {
            taxed_operation:0,
            exonerated_operation:0,
            unaffected_operation:0,
            discount:0,
            subtotal:0,
            igv:0,
            total:0
        }
        let detail = context.state.shopping_detail;

        for (let index = 0; index < detail.length; index++) {
            if (detail[index].igv == '10') {
                total.taxed_operation += parseFloat(detail[index].total_price);
            }
            if (detail[index].igv == '20') {
                total.exonerated_operation += parseFloat(detail[index].total_price);
            }
            if (detail[index].igv == '30') {
                total.unaffected_operation += parseFloat(detail[index].total_price);
            }
        }
        total.total = (total.taxed_operation + total.exonerated_operation + total.unaffected_operation);
        total.igv = total.total - (total.taxed_operation / 1.18);
        total.subtotal = total.total  - total.igv;
        

        total.taxed_operation = total.taxed_operation.toFixed(2);
        total.exonerated_operation = total.exonerated_operation.toFixed(2);
        total.unaffected_operation = total.unaffected_operation.toFixed(2);
        total.discount = total.discount.toFixed(2);
        total.subtotal = total.subtotal.toFixed(2);
        total.igv = total.igv.toFixed(2);
        total.total = total.total.toFixed(2);
            
        context.commit('mTotalsShopping',total);
        

    },



}

//to handle mutations
const mutations = {

    mAddShoppingDetail(state, detail) {
        state.shopping_detail.push(detail);
    },

    mDeleteShoppingDetail(state, index) {
        state.shopping_detail.splice(index,1);
    },

    mTotalsShopping(state,total){
        state.total_shopping = total;
    }
    ,
    mResertShoppingDetail(state) {
        state.shopping_detail = [];
    },

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}